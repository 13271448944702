.templateLabel {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    color: #000000;
}

.templateCard {
    background: #FFFFFF;
    border: 4px solid #000000;
    box-shadow: 4px 4px 0 #000000;
    border-radius: 12px;
    padding: 24px;
}

.templateCardName {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.templateCardCreatedAt {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    /* identical to box height, or 138% */

    color: #757575;
}

.templateCardTemplate {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    /* or 138% */
    color: #000000;
}

.exploreBtn {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    /* identical to box height, or 138% */

    text-align: right;
    text-underline-mode: none;
    color: #000000;
}


.templateCardContainerName {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 58px;
    color: #000000;
}

.templateCardContainerInputName {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 36px;
    line-height: 58px;
    color: #000000;
    background: none;
    outline: none;
    border: none;
    font-style: italic;
}

.templateCardInputLabel {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */


    color: #000000;


}

.templateCardInput {
    filter: drop-shadow(2px 2px 0px #000000);
    width: 100%;
    height: 140px;
    background: #FFFFFF;
    border: 2px solid #000000;
    border-radius: 8px;
    outline: none;
    margin-top: 17px;
    margin-bottom: 17px;
    padding: 10px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    min-height: 150px;
    font-size: 13px;
    line-height: 18px;
    /* identical to box height, or 138% */
    color: #000000;
    resize: vertical;
}

.templateCardTemplateView {
    filter: drop-shadow(2px 2px 0px #000000);
    width: 100%;
    height: 140px;
    background: #FFFFFF;
    border: 2px solid #000000;
    border-radius: 8px;
    outline: none;
    margin-top: 17px;
    margin-bottom: 17px;
    padding: 10px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    /* identical to box height, or 138% */
    color: #000000;
    resize: none;
}

.templateCardUpdateDeleteBtn {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    /* identical to box height, or 138% */

    text-align: right;
    color: #000000;
    background: none;
    outline: none;
    border: none;
}

.templateCardUpdateDeleteBtn:hover {
    outline: 1px solid black;
    border-radius: 9px;
    background: black;
    color: white;
    cursor: pointer;
}

.templateCardExecuteBtn {
    padding: 16px 32px;
    width: 170px;
    height: 57px;

    background: #000000;
    border-radius: 12.406px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height, or 138% */

    text-decoration-line: underline;

    color: #FFFFFF;
    box-shadow: 3px 3px 0 #c7c7c7;
    border: none;

}

.templateCardExecuteBtn:active {
    outline: 2px solid #ffffff;
    box-shadow: none;
}

.templateCardExecuteBtn:disabled {
    visibility: hidden;
}
