.App {
  text-align: center;
}

.load-rainbow div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background: linear-gradient(to right,
  #ff0000,
  #ffff00,
  #008000,
  #0000ff,
  #ee82ee,
  #ff0000
  ) repeat-x;
  animation: rainbow 3s cubic-bezier(0, 1.11, 1, .54) infinite;
  background-size: 200% 100% !important;
}

.load-rainbow {
  padding: 0;
  margin: 0;
  animation: rainbowContainer 3s cubic-bezier(0, 1.11, 1, .54) normal;
}


@keyframes rainbow {
  0% {
    background-position: 0;
  }
  100% {
    background-position: 800% 0;
  }
}

@keyframes rainbowContainer {
  0% {
    width: 0;
  }
  100% {
    width: 99.5%;
  }
}
